// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

/**
 * @deprecated remove on FF clean up bandicoots-compiled-migration-smartcard
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const breakWordsCss = css({
	whiteSpace: 'pre-line',
	wordBreak: 'break-all',
});
